import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 5
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"TWO"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "Why is it important to create opportunities for real contribution?"
        }
        answers={[
          "It helps athletes feel they are valued as a member of the team",
          "It take the burden of set-up off the coach",
          "It saves time during practice",
          "It helps discipline problematic players",
        ]}
        correctAnswerNumber={0}
        answerDetail={`Creating opportunities to matter helps girls understand how they are essential to the team. It can also help athletes heal from the effects of stress and trauma that they may be facing in other parts of their lives.`}
        nextLink={nextLink}
        nextText={"RESULTS"}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 2, 6)
